:root {
  --primary: #6a59ff;
  --white: #ffffff;
  --bg: #f5f5f5;
}
.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.card {
  width: 300px;
  height: 500px;
  border-radius: 25px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  color: rgb(240, 240, 240);
  box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);
  background-color: black;

  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
    transition: opacity 0.2s ease-out;
  }

  h2 {
    position: absolute;
    inset: auto auto 30px 30px;
    margin: 0;
    width: 85%;
    font-size: 17px;
    transition: inset 0.3s 0.3s ease-out;
    text-transform: uppercase;
    color: transparent;
    border-radius: 10px;
    padding: 0 10px 0 10px;
  }

  p,
  a {
    position: absolute;
    opacity: 0;
    max-width: 80%;
    transition: opacity 0.3s ease-out;
    margin: 0;
  }

  p {
    inset: auto auto 80px 30px;
  }

  a {
    inset: auto auto 40px 30px;
    color: inherit;
    text-decoration: none;
  }

  &:hover h2 {
    inset: auto auto 210px 30px;
    transition: inset 0.3s ease-out;
    color: #64ffda;
    background-color: transparent;
    padding: 0 !important;
    font-size: 18px;
  }

  &:hover p,
  &:hover a {
    opacity: 1;
    transition: opacity 0.5s 0.1s ease-in;
  }

  &:hover img {
    transition: opacity 0.3s ease-in;
    opacity: 0.5;
    filter: blur(8px);
  }
}

.material-symbols-outlined {
  vertical-align: middle;
}
